/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) { 
  //const valid_map = ['admin', 'гость']
  //return valid_map.indexOf(str.trim()) >= 0
  const reg = /^[а-яА-ЯA-Za-z0-9_ \-@.]{7,20}$/
  return reg.test(str);
}